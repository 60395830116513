import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import shortid from 'shortid';
import ReactSelect from 'react-select';
import moment from 'moment-timezone';
import {
  Button, Row, Form, Col,
} from 'react-bootstrap';
// Services
import {
  getActivities, getPatientInfo, createBulkActivities,
} from '../../../services/patient';
import { formatPhone } from '../../../services/helpers';
// Actions
import ShowNotification from '../../../actions/notification';
import { PatientTimer, UpdatePatient } from '../../../actions/patient';
// Constants
import {
  NOTIFICATION_TYPE,
  DIALOG_STYLES,
  DATE_FORMAT,
  AUDITING_TITLE_OPTIONS,
  CALL_ATTEMPTS_OPTIONS,
  AUDITING_TITLE_OPTIONS_REQUIRES_PHONE,
} from '../../../constants/constants';
import { TIME_TRACKING_NOTES } from '../../../constants/templates';
import { AUDITING_PAGE_SIZE } from '../../../constants/pageSizes';
// Components
import { Select } from '../../base/forms/Select';
import { Checkbox } from '../../base/forms/Checkbox';
import { TextArea } from '../../base/forms/TextArea';
import { TextInput } from '../../base/forms/TextInput';
// Custom Hooks
import useConditionalFetch from '../../../hooks/useConditionalFetch';

export function AuditingModal(props) {
  const {
    patientCanBeBilled, initialActivity, isModalOpen, showNotification,
    setIsModalOpen, timezone, callbackAction = null, patientId, updatePatient,
    timer, patientTimer,
  } = props;

  const DEFAULT_ACTIVITY_GLOBAL = {
    cnName: null,
    directContactIsMade: false,
    date: moment.tz(timezone).format(DATE_FORMAT.FULL_SERVER_WITH_TIME),
  };

  const DEFAULT_ACTIVITY = {
    id: null,
    title: '',
    notes: '',
    billed: null,
    cnName: null,
    duration: 0,
    billable: patientCanBeBilled,
    directContactIsMade: false,
    date: moment.tz(timezone).format(DATE_FORMAT.FULL_SERVER_WITH_TIME),
    permissions: {},
    phone: null,
  };

  const [shouldFetch, setShouldFetch] = useState(false);
  const [systemTime, setSystemTime] = useState(0);
  const [remainingTime, setRemainingTime] = useState(0);
  const [monthToDateTime, setMonthToDateTime] = useState(0);
  const [currentDuration, setCurrentDuration] = useState('');

  const requiresPhone = title => AUDITING_TITLE_OPTIONS_REQUIRES_PHONE
    .find(option => option.value === title) || false;

  const addActivity = (formik) => {
    formik.setFieldValue('activities', [...formik.values.activities, DEFAULT_ACTIVITY]);
  };

  const { data: patientInfoData } = useConditionalFetch(
    () => getPatientInfo(patientId), shouldFetch,
  );

  const loadPatientAuditing = () => {
    const {
      auditing: { currentPage = 0 } = {},
    } = props;
    const params = {
      pageSize: AUDITING_PAGE_SIZE,
      pageNumber: currentPage,
    };
    const getActivitiesRequest = getActivities(patientId, params);
    const getActivitiesPromise = getActivitiesRequest.promise;

    getActivitiesPromise.then((data) => {
      delete getActivitiesRequest.promise;
      updatePatient({ auditing: data || {} });
      setMonthToDateTime(data?.totalCcmMtd === null ? 100 : data?.totalCcmMtd);
    }).catch((error) => {
      delete getActivitiesRequest.promise;
      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: 'Could not load patient tracking data, please try again later',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  useEffect(() => {
    loadPatientAuditing();
  }, []);

  useEffect(() => {
    if (patientInfoData) updatePatient(patientInfoData);
    setShouldFetch(false);
  }, [patientInfoData]);

  const handleCloseModal = () => {
    if (callbackAction) callbackAction();
    setIsModalOpen(false);
  };

  const saveActivities = (data) => {
    const updatedData = data?.activities?.map(activity => ({
      ...activity,
      uniqueKey: shortid.generate(),
      cnName: data.cnName,
      directContactIsMade: data.directContactIsMade,
      date: data.date,
      phone: requiresPhone(activity.title) ? activity.phone : null,
      timerId: timer.id,
    }));

    const addActivityRequest = createBulkActivities(patientId, updatedData);
    const addActivityPromise = addActivityRequest.promise;

    return addActivityPromise.then(() => {
      delete addActivityPromise.promise;
      loadPatientAuditing();
      updatedData.forEach((activity) => {
        if (CALL_ATTEMPTS_OPTIONS.includes(activity.title)) {
          setShouldFetch(true);
        }
      });
      if (callbackAction) callbackAction();
      handleCloseModal();
    }).catch((error) => {
      delete addActivityPromise.promise;

      if (error.isCanceled) {
        return;
      }
      if (error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: 'An error occurred while attempting to save time tracking data, please try again later.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  const fieldStyles = {
    formGroup: 'mb-0',
    formLabel: 'd-none',
  };

  const getTimerValue = () => {
    const [timerMinuteStr, timerSecondStr] = timer?.time?.split(':');
    patientTimer({ isTimerOn: false });

    if (timerMinuteStr && timerSecondStr) {
      const timerMinutes = parseInt(timerMinuteStr, 10);
      const timerSeconds = parseInt(timerSecondStr, 10);

      // Round the seconds to the nearest minute
      const roundedTimerValue = timerMinutes + Math.round(timerSeconds / 60);
      setSystemTime(roundedTimerValue);
      setRemainingTime(roundedTimerValue);
    }
  };

  useEffect(() => {
    if (isModalOpen) getTimerValue();
  }, [isModalOpen]);

  const activitySchema = Yup.object({
    notes: Yup.string(),
    title: Yup.string().nullable().required('Required'),
    duration: Yup.number()
      .required('Required')
      .min(0, 'Minimum limit is 0')
      .max(120, 'Maximum limit is 120'),
    phone: Yup.string()
      .when('title', (fieldValue, schema) => (requiresPhone(fieldValue)
        ? schema.nullable().min(14, 'Required').required('Required') : schema.nullable())),
  });

  const validationSchema = Yup.object().shape({
    activities: Yup.array().of(activitySchema),
  });
  const getMinuteDisplay = time => `${time && time > -1 ? time : 0} minute${time === 1 ? '' : 's'}`;

  const updateRemainingTime = (activities) => {
    const totalDuration = activities?.reduce((sum, activity) => sum + activity.duration, 0);
    setRemainingTime(systemTime - totalDuration);
  };

  const renderInfoBanner = () => (
    <Row className="audit-info-banner">
      <Col xs={7} data-test="auditingInfoBanner_systemCaptured">
        {`System Captured ${getMinuteDisplay(systemTime)} / ${getMinuteDisplay(remainingTime)} to log`}
      </Col>
      <Col xs={5} className="text-right" data-test="auditingInfoBanner_ccmMtd">
        {`Total CCM Time MTD: ${monthToDateTime} min`}
      </Col>
    </Row>
  );

  const renderAuditRow = (formik, index) => (
    <div key={index} className="text-left dialog-content">
      <Row className={`align-items-center pt-2 ${index > 0 ? 'border-top' : ''}`}>
        <div className="col audit-entry-field" data-test="auditingModal_reasonsSelect">
          <Select
            label="Time Tracking"
            name={`activities[${index}].title`}
            options={AUDITING_TITLE_OPTIONS}
            disabled={formik.values.activities[index].billed}
            onChange={(option) => {
              // Clear the phone value when the user changes to an activity type
              // that doesn't require a phone number
              if (!requiresPhone(formik.values.activities[index].title)) {
                formik.setFieldValue(`activities[${index}].phone`, null);
              }
              formik.setFieldValue(`activities[${index}].title`, option && option.value ? option.value : null);
            }}
            styles={{
              formInvalid: 'bi-exclamation-circle-fill',
            }}
          />

        </div>
        <div className="col audit-entry-field">
          <TextInput
            label="Minutes"
            type="number"
            min="1"
            max="120"
            name={`activities[${index}].duration`}
            data-test="auditing_durationField"
            className={currentDuration === `auditing_durationField${index}` && remainingTime < 0 ? 'duration-warning-border' : ''}
            disabled={formik.values.activities[index].permissions?.canTimeBeEdited === false}
            onFocus={() => setCurrentDuration(`auditing_durationField${index}`)}
            styles={{
              formInvalid: 'bi-exclamation-circle-fill',
            }}
          />
          {currentDuration === `auditing_durationField${index}` && remainingTime < 0 && (
          <div className="duration-warning invalid-feedback dialog-field-error bi-exclamation-circle-fill">
            Exceeds captured time
          </div>
          )}
        </div>
        <div className="col audit-entry-field">
          {requiresPhone(formik.values.activities[index].title) && (
            <TextInput
              label="Phone Number"
              value={(
                formik.values.activities[index].phone === null
                  ? '' : formatPhone(formik.values.activities[index].phone)
              )}
              name={`activities[${index}].phone`}
              data-test="auditing_phoneField"
              styles={{
                formInvalid: 'bi-exclamation-circle-fill',
              }}
            />
          )}
        </div>
        <div className="col" />
      </Row>
      <Row className="align-items-center">
        <div className="col col-9 pt-1 audit-entry-field">
          <TextArea
            label="Notes"
            name={`activities[${index}].notes`}
            rows={5}
            data-test="auditing_notesField"
          />
          <div
            className="position-absolute"
            style={{ top: 0, right: 35, width: 200 }}
          >
            <ReactSelect
              name="template"
              className="border-bottom-0"
              placeholder="Add a custom template"
              searchable
              clearable={false}
              options={TIME_TRACKING_NOTES}
              onChange={ev => formik.setFieldValue(
                `activities[${index}].notes`, ev && `${formik.values.activities[index].notes.length ? `${formik.values.activities[index].notes}\n\n` : ''}${ev.value}`,
              )}
              data-test="auditingModal_templateSelect"
            />
            <Button
              size="sm"
              variant="link-dark"
              className="position-absolute"
              style={{ bottom: 5, right: -25 }}
              onClick={() => formik.setFieldValue(`activities[${index}].notes`, '')}
              disabled={!formik.values.activities[index].notes.length}
              data-test="auditingModal_trashBtn"
            >
              <i className="d-flex-center bi-trash" />
            </Button>
          </div>
        </div>
        <div className="col">
          {index > 0 && (
          <Button
            className="w-100 remove-activity-button"
            variant="danger"
            data-test="auditingModal_removeActivityBtn"
            onClick={() => {
              const updatedActivities = formik.values.activities.filter((_, i) => i !== index);
              formik.setFieldValue('activities', updatedActivities);
            }}
          >
            <i className="d-flex-left bi-x-circle mr-2" />
            Remove
          </Button>
          )}
        </div>
      </Row>
    </div>
  );

  return (
    <Modal
      isOpen={isModalOpen}
      style={DIALOG_STYLES}
      onRequestClose={() => handleCloseModal()}
      shouldCloseOnOverlayClick={false}
      contentLabel="Auditing Activities Modal"
      data-test="auditingModal_modal"
    >
      <div className={`simple-dialog ${initialActivity ? 'medium-dialog' : 'big-dialog'}`}>
        <div className="dialog-title">
          {`${initialActivity ? 'Edit' : 'Add'} Time Tracking Entry`}
          <button
            type="button"
            className="close-icon i-close"
            onClick={() => handleCloseModal()}
            data-test="auditingModal_modalCloseButton"
          />
        </div>
        <Formik
          initialValues={{
            ...DEFAULT_ACTIVITY_GLOBAL,
            activities: [DEFAULT_ACTIVITY],
          }}
          validationSchema={validationSchema}
          onSubmit={(values, { resetForm }) => {
            const formattedDate = moment(values.date).format(DATE_FORMAT.FULL_SERVER);
            const updatedValues = { ...values, date: formattedDate };
            saveActivities(updatedValues);
            resetForm();
            patientTimer({ reset: true });
          }}
          data-test="auditingModal_formikComponent"
        >
          {(formik) => {
            useEffect(() => updateRemainingTime(formik?.values?.activities),
              [formik.values.activities, updateRemainingTime]);

            return (
              <Form>
                <div className="text-left dialog-content">
                  {renderInfoBanner()}
                  <Row className="align-items-center">
                    {formik.values.cnName && (
                      <div className="col">
                        <Form.Group>
                          <Form.Label>CN</Form.Label>
                          <p>{formik.values.cnName}</p>
                        </Form.Group>
                      </div>)}
                    <div className="col">
                      <Form.Group>
                        <Form.Label>Date</Form.Label>
                        <p>
                          {moment(formik.values.date, DATE_FORMAT.FULL_SERVER_WITH_TIME)
                            .format(DATE_FORMAT.FULL_WITH_TIME_IN_12_HOURS)}
                        </p>
                      </Form.Group>
                    </div>
                    <div className="col mt-3">
                      <Checkbox
                        label={formik.values.directContactIsMade ? 'Direct' : 'Not direct'}
                        name="directContactIsMade"
                        type="switch"
                        styles={fieldStyles}
                      />
                    </div>
                    <div className="col">
                      <Button
                        data-test="auditingModal_addTrackingEventBtn"
                        onClick={() => {
                          addActivity(formik);
                        }}
                      >
                        <i className="d-flex-left bi-plus-lg mr-2" />
                        Add Tracking Event
                      </Button>
                    </div>
                  </Row>
                </div>
                <div className="audit-entries">
                  {formik.values?.activities?.map((_, index) => renderAuditRow(formik, index))}
                </div>
                <div className="dialog-buttons justify-content-end px-4">
                  <Button variant="light" onClick={() => handleCloseModal()} data-test="auditingModal_cancelBtn">Cancel</Button>
                  <Button
                    variant="primary"
                    className="ml-2"
                    onClick={() => formik.handleSubmit()}
                    disabled={!formik.isValid || !Object.keys(formik.touched).length}
                    data-test="auditingModal_saveBtn"
                  >
                    Save
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
}

export function mapStateToProps(state) {
  const patientPermissions = state.patient.permissions || {};

  return {
    timezone: state.tenant && state.tenant.timezone,
    auditing: state.patient && state.patient.auditing,
    timer: state.patient && state.patient.timer,
    patientCanBeBilled: patientPermissions.canBeBilled,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updatePatient: patientData => dispatch(UpdatePatient(patientData)),
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
    patientTimer: elem => dispatch(PatientTimer(elem)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AuditingModal);
